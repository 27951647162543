$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1500px
);

:root {
  --header-heigth: 60px;
  --footer-heigth: 294px;
}

@keyframes bounce {
  from {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

$color-main: #602719;
$color-main-dark: #5c2014;
$color-main-2x-dark: #561d0f;

$color-alert: #e41e3f;

$color-beige: #d2ad93;
$color-beige-light: #e9ded2;
$color-beige-2x-light: #f8f3e8;
$color-beige-4x-light: #faf7f4;
$color-beige-transparent: #e9ded269;

$color-main-light: #f1d1d1;

$color-white: #ffffff;
$color-white-dark: #f5f5f5;
$color-white-2x-dark: #d7d7d7;

$color-grey-btn: #efe9e7;
$color-grey: #f2f2f2;
$color-grey-dark: #dcdcdc;
$color-grey-2x-dark: #c8c8c8;
$color-grey-2x-dark: #c8c8c8;
$color-grey-3x-dark: #b4b4b4;
$color-grey-4x-dark: #a1a1a1;
$color-grey-8x-dark: #6d6d6d;
$color-grey-16x-dark: #333333;

$color-red: #f44336;
$color-orange: #fa6400;
$color-green: #8cd855;

$color-black: #000000;
$color-black-2x-light: #262626;


$color-red-profil: #ffc6c6;
$color-blue-profil: #DBBFA0;
$color-pink-profil: #FDE5CB;
$color-green-profil: #C5A68F;
$color-yellow-profil: #D5B18A;

$color-pink-clement: #d13232;

$ratio-picture: 1.777777;
$height-picture-carousel: 350px;
$width-picture-carousel: $height-picture-carousel * $ratio-picture;

$height-big-picture-carousel: 534px;
$width-big-picture-carousel: $height-big-picture-carousel * $ratio-picture;
