@import 'src/assets/styles/styles-variables';

button:focus {
  outline: none;
}

.btn {
  font-size: 14px;
  padding: 9px 19px 9px;
  text-align: center;
  outline: none;
  border-radius: 15px;
  cursor: pointer;
  background-color: $color-beige-2x-light;
  border: none;
  background-position: center;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  letter-spacing: 0.5px;
  height: fit-content;

  .icon {
    height: 18px;
    margin-right: 15px;
  }

  &:hover {
    background: $color-white-dark radial-gradient(circle, transparent 1%, $color-white-dark 1%) center/15000%;
  }

  &:active {
    background-color: $color-white-2x-dark;
    transition: background 0s;
  }
}


@-webkit-keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@mixin button($bcolor,
$url,
$x1,
$y1,
$bor,
$col) {
  background: $bcolor;
  -webkit-mask: url($url);
  mask: url($url);
  -webkit-mask-size: $x1 $y1;
  mask-size: $x1 $y1;
  border: $bor;
  color: $col;
}

.btn-liquid-main {
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  border-radius: 10px;

  .mas {
    position: absolute;
    color: $color-main;
    text-align: center;
    width: 101%;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    padding: 11px 22px;
  }

  button {
    width: 101%;
    mask: url(/assets/images/home/effect-liquid.png);
    mask-size: 7100% 100%;
    border: none;
    cursor: pointer;
    animation: ani2 0.7s steps(70) forwards;
    padding: 11px 22px;
    background: $color-main;
    color: $color-white;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      -webkit-animation: ani 0.7s steps(70) forwards;
      animation: ani 0.7s steps(70) forwards;
    }

    &.no-anim {
      animation: none;
    }
  }
}

.btn-raised-beige {
  background-color: $color-beige;
  border: 1px solid $color-beige;
  color: $color-main;

  &:hover {
    background: $color-main;
    border-color: $color-main;
    color: $color-white;
  }

  &:active {
    background-color: $color-main;
    transition: background 0s;
  }
}

.btn-raised-white {
  background-color: $color-white;
  border: 1px solid $color-white;
  color: $color-main;

  &:hover {
    background: $color-main;
    border-color: $color-white;
    color: $color-white;
  }

  &:active {
    background-color: $color-main;
    transition: background 0s;
  }
}

.btn-raised-main-border {
  background: none;
  color: $color-main;
  border: 1px solid $color-main;

  &:hover {
    background: $color-main;
    color:$color-white;
  }

  &:active {
    background: $color-main;
    color: $color-white;
  }
}

.btn-raised-red-border {
  background: none;
  color: $color-red;
  border: 1px solid $color-red;

  &:hover {
    background: $color-red;
    color:$color-white;
  }

  &:active {
    background: $color-red;
    color: $color-white;
  }
}

.btn-raised-red {
  background-color: $color-red;
  border: 1px solid $color-red;
  color: $color-white;

  &:hover {
    border-color: $color-red;
    color: $color-red;
  }

  &:active {
    border-color: $color-red;
    color: $color-red;
    transition: background 0s;
  }
}

.btn-raised-main {
  background-color: $color-main;
  border: 1px solid $color-main;
  color: $color-white;

  &.disabled {
    background: $color-grey-dark;
    border-color: $color-grey-dark;
    color: $color-black;
    opacity: 50%;
    cursor: default;

    &:hover {
      background: $color-grey-dark;
      border-color: $color-grey-dark;
      color: $color-black;
    }
  }

  &:hover {
    background: $color-beige-light;
    border-color: $color-beige-light;
    color: $color-main;
  }

  &:active {
    background-color: $color-beige-light;
    transition: background 0s;
  }

  &.lock {
    &:hover {
      background-color: $color-main;
      border: 1px solid $color-main;
      color: $color-white;
    }

    &:active {
      background-color: $color-main;
      border: 1px solid $color-main;
      color: $color-white;
    }
  }
}

.btn-basic-main {
  border-color: $color-beige-2x-light;
  color: $color-main;

  &:hover {
    color: $color-main;
    background-color: $color-white-dark;
  }

  &:active {
    background-color: $color-white-2x-dark;
    transition: background 0s;
  }
}

.btn-basic-white {
  border-color: $color-white;
  background-color: $color-white;
  color: $color-main;

  &:hover {
    color: $color-main;
    border-color: $color-white-dark;
    background-color: $color-white-dark;
  }

  &:active {
    background-color: $color-white-2x-dark;
    border-color: $color-white-2x-dark;
    transition: background 0s;
  }
}

.btn-bold {
  font-weight: 600;
}

.btn-blog-raised {
  border-radius: 29.5px;
  background-color: $color-black;
  color: $color-white;
}

.btn-icon {
  font-size: 15px;
  padding: 13px 13px;
  text-align: center;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.8s;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  .icon {
    height: 18px;
    margin-right: 15px;
  }

  &:hover {
    background: $color-white-dark radial-gradient(circle, transparent 1%, $color-white-dark 1%) center/15000%;
  }

  &:active {
    background-color: $color-white-2x-dark;
    transition: background 0s;
  }
}

.link {
  color: $color-black;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  background-position: center;

  &:hover {
    text-decoration: underline;
  }
}

.nav-btn {
  outline: none;
  cursor: pointer;
  border: none;
}

.link-main {
  color: $color-main;
  cursor: pointer;

  &:hover {
    color: $color-main-dark;
    text-decoration: underline;
  }

  &.disabled {
    color: $color-main;
    opacity: 0.3;
    cursor: auto;

    &:hover {
      color: $color-main;
      text-decoration: none;
    }
  }
}

ul.btn-dot {
  font-size: 16px;
  cursor: pointer;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 12px;
  border-radius: 10px;
  gap: 3px;

  &:hover {
    background-color: $color-beige;
  }

  li {
    background: none repeat scroll 0 0 $color-main;
    height: 5px;
    width: 5px;
    line-height: 0;
    list-style: none outside none;
    vertical-align: top;
    border-radius: 50%;
    pointer-events: none;
  }

  &.horizontal {
    flex-direction: column;

    li {
      margin-top: 2px;
      margin-bottom: 2px;
      margin-right: 0;
    }
  }
}

.btn-hover-main {
  border: none;

  &:active {
    background: none;
  }

  &.selected {
    background-color: $color-main-light;
  }

  &:hover {
    background: $color-main-light none;
  }
}

