@import 'src/assets/styles/styles-variables';

.dropdown {
  position: relative;
  width: fit-content;

  .dropdown-title {
    .arrow::before {
      font-family: StoryElement, sans-serif;
      content: '<';
      display: inline-block;
      margin-top: -4px;
      transition: transform 0.3s;
      margin-left: 15px;
      font-size: 24px;
      transform: rotate(-90deg);
    }

    &.link {
      display: inline-flex;
      align-items: center;
      user-select: none;
      justify-content: space-between;
      cursor: pointer;
    }

    &.btn {
      display: inline-flex;
      align-items: center;
      user-select: none;
      justify-content: space-between;
      padding: 14px 16px 9px;

      &.btn-bold {
        padding: 10px 16px 10px;
      }

      .arrow {
        color: $color-main;
      }

      &.btn-raised-main {
        .arrow {
          color: $color-white;
        }
      }
    }
  }

  .dropdown-content {
    font-size: 14px;
    position: absolute;
    z-index: 2;
    top: 60px;
    left: 0;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-color: $color-white;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    color: $color-black;
    overscroll-behavior: none;
    display: flex;
    flex-direction: column;

    &.dropdown-content-link {
      top: 30px;
      left: initial;
      right: 0;
    }

    .classic-content {
      padding: 22px 26px 0 18px;

      .item {
        white-space: nowrap;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    a {
      display: block;
    }
  }

  &.show .dropdown-content {
    transition: max-height 0.3s ease;
    max-height: 550px;
  }

  &.show .arrow:before {
    transform: rotate(90deg);
  }
}
