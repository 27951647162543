@import 'src/assets/styles/styles-variables';

.bloc-input {
  width: 100%;
  margin-bottom: 21px;

  label {
    width: 100%;

    &.password {
      position: relative;

      img.eye-password {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
      }
    }

    textarea.input {
      padding-top: 14px;
      max-width: 100%;
      min-width: 100%;
      min-height: 50px;
    }

    .input {
      width: 100%;
      border-radius: 15px;
      background-color: $color-beige-2x-light;
      height: 48px;

      border: none;
      margin: 0;
      padding: 0 45px 0 23px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 15px;
      transition: border 0.15s ease-in;

      &:focus-visible {
        outline: none;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $color-grey-4x-dark;
        font-size: 14px;
      }
    }

  }

  .input-error {
    text-align: left;
    color: $color-red;
    font-size: 12px;
    padding: 5px 0 5px 17px;
    display: none;
  }

  .input-info {
    text-align: left;
    color: $color-grey-3x-dark;
    font-size: 12px;
  }

  &.show-error {
    margin-bottom: 0;

    &.info {
      margin-bottom: 21px;
    }

    .input {
      border: 1px solid $color-red;
    }

    .input-error {
      display: block;
    }

    .input-info {
      padding-bottom: 10px;
    }
  }


}

/* Basic styles */
input[type="checkbox"],
input[type="radio"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
  height: fit-content;
}

.checkbox, .radio {
  position: relative;
  display: inline-block;
  padding: 2px 0 0 2em;
  cursor: pointer;
  text-align: left;
  margin-left: 4px;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 22px;
    height: 22px;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
  }

  &:before {
    content: " ";
    border: 1px solid $color-black;
    border-radius: 20%;
  }
}


input[type="checkbox"] + .checkbox::after {
  content: "\2714";
  color: $color-white;
  text-align: center;
  padding-left: 1px;
  padding-top: 1px;
}

input[type="radio"] + .radio::before {
  border-radius: 50%;
}

input[type=radio] + .radio::after {
  content: " ";
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
  background: $color-main;
  border: .2em solid $color-white;
  border-radius: 50%;
}

/* :checked */
input[type="checkbox"]:checked + .checkbox::before,
input[type="radio"]:checked + .radio::before {
  background: $color-main;
  border-color: $color-main;
}

input[type="checkbox"] + .checkbox::after,
input[type=radio] + .radio::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

input[type="checkbox"]:checked + .checkbox::after,
input[type=radio]:checked + .radio::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.toggle-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .toggle-text {
    text-align: left;

    .toggle-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 13px;
    }

    .toggle-sub-title {
      font-size: 12px;
      color: $color-grey-8x-dark;
    }
  }

  .normal {
    min-width: 75px;

    @media (max-width: map-get($grid-breakpoints, lg)) {
      min-width: 60px;
    }

    label {
      width: 60px;
      height: 30px;
      display: inline-block;
      position: relative;
      transition: all 0.3s ease;
      transform-origin: 20% center;
      cursor: pointer;
      background: $color-grey-dark;
      border: none;
      box-shadow: none;
      border-radius: 20px;


      &:before {
        display: block;
        transition: 0.3s ease;
        content: "";
        width: 28px;
        height: 28px;
        background: $color-white;
        transform: rotate(-25deg);
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 100%;
        border: solid 1px $color-grey-8x-dark;

      }
    }
  }

  .normal-checkbox:checked + label {
    background: $color-beige;

    &:before {
      left: 31px;
      border: none;
      width: 26px;
      height: 26px;
      top: 2px;
    }
  }
}

