@import 'src/assets/styles/styles-variables';

.scroll-red {
  overscroll-behavior: contain;
  scrollbar-width: auto;
  scrollbar-color: $color-main $color-white;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $color-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-main;
    border-radius: 10px;
    border: 2px solid $color-white;
  }
}
