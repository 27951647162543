.bg-color-0 {
  background-color: #F5E3B9;
}

.bg-color-1 {
  background-color: #DBBFA0;
}

.bg-color-2 {
  background-color: #FDE5CB;
}

.bg-color-3 {
  background-color: #C5A68F;
}

.bg-color-4 {
  background-color: #D5B18A;
}

.color-0 {
  color: #7e6858;
  background-color: #F5E3B9;
}

.color-1 {
  color: #7e6858;
  background-color: #DBBFA0;
}

.color-2 {
  color: #7e6858;
  background-color: #FDE5CB;
}

.color-3 {
  color: #7e6858;
  background-color: #C5A68F;
}

.color-4 {
  color: #7e6858;
  background-color: #D5B18A;
}
