@import 'src/assets/styles/styles-variables';

.modal-title {
  text-align: center;
  font-size: 28px;
  font-family: StoryElement, sans-serif;
  padding: 31px 45px 15px;
}

.modal-content {
  padding: 24px 50px 24px 24px;
  width: 100%;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding: 24px 30px;
  }

  .title {
    color: $color-black-2x-light;
    margin: 0 0 16px;
    text-align: center;
    font-size: 28px;
    font-family: StoryElement, sans-serif;
    padding: 15px 45px 15px;
  }

  p {
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: $color-black-2x-light;
  }
}

.modal-btn {
  padding: 14px 24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;

  .link, .link-main, .btn {
    margin-right: 16px;
    font-size: 22px;
    padding: 9px 23px 7px;
    font-family: StoryElement, serif;
  }
}
