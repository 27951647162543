@import 'src/assets/styles/styles-variables';

.alert {
  border: 1px solid $color-grey;
  padding: 18px;
  display: flex;
  align-items: center;
  margin: 32px 0;
  text-align: left;

  .icon-circle {
    width: 33px;
    min-width: 33px;
    border: 1px solid;
    border-radius: 50px;
    padding: 4px 7px 4px 7px;
    margin-right: 25px;
  }

  .message {
    font-size: 14px;
    line-height: 16px;
  }

  &.alert-error {
    svg {
      border-color: $color-red;
      color: $color-red
    }
  }

  &.alert-success {
    svg {
      padding: 7px;
      border-color: $color-green;
      color: $color-green
    }
  }

}



