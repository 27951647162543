@import 'src/assets/styles/styles-variables';

.icon-circle {
  width: 33px;
  min-width: 33px;
  border: 1px solid;
  border-radius: 50px;
  padding: 3px 7px 3px 7px;
  margin-right: 25px;
}

.icon-error {
  border-color: $color-red;
  color: $color-red
}

.icon-success {
  padding: 7px;
  border-color: $color-green;
  color: $color-green
}
