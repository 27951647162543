@import './styles-variables';
@import 'components/styles-input';
@import 'components/styles-btn';
@import 'components/styles-fonts';
@import 'components/styles-card';
@import 'components/styles-alert';
@import 'components/styles-icon';
@import 'components/styles-dropdown';
@import 'components/styles-color';
@import 'components/styles-tooltip';
@import 'components/styles-modal';
@import 'components/styles-scroll';

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html, body, textarea, button, input {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: $color-black;

  &:hover {
    text-decoration: none;
    color: $color-black;
  }
}

.center-vertically {
  display: flex;
  align-items: center;
}

.w100 {
  width: 100%;
}

.hide {
  display: none;
}

.hidden {
  visibility: hidden;
  height: 0;
}

.hidden-desktop {
  visibility: hidden;
  height: 0;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    display: none;
  }
}

.content {
  min-height: calc(100% - var(--header-heigth));
  display: table;
  height: calc(100% - var(--header-heigth));
  width: 100%;
  object-fit: cover;
}

.container-with-border {
  display: flex;
  flex-wrap: wrap;
}

.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    min-width: 100%;
    width: 100%;
  }
}

.page-grey {
  background-color: $color-white;
  min-height: 100%;
  height: 100%;
}

img.blurred-picture {
  position: absolute;
  left: 0;
  right: 0;
  object-fit: cover !important;
  z-index: -1;
  filter: blur(30px);
  margin: -7% 0 0 -7%;
  width: 125% !important;
  height: 125% !important;
}

.grey-block {
  background-color: $color-grey-dark;
  margin-top: 5px;
}

.content-text-comment a, .text-description a {
  color: $color-main;

  &:hover {
    color: $color-main-2x-dark;
  }
}


.header.hide {
  transform: translateY(-100%);

  .dropdown.show .dropdown-content {
    max-height: 0 !important;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    .search-component {
      display: none !important;
    }
  }
}

.bloc-empty {
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  color: #000;
  margin-top: 150px;
}

.loader-long {
  margin: 150px 0;
}

.loader-center {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  left: 0;
  right: 0;
}


.sub-menu-notification  {
  b {
    display: contents;
    color: $color-main;
  }

  .time {
    color: $color-grey-4x-dark;
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .hide-mobile {
    display: none;
  }
}

html body button#axeptio_main_button {
  bottom: 100px !important;
  left: 40px !important;

  @media (max-width: map-get($grid-breakpoints, xxl)) {
    bottom: 35px !important;
    left: 30px !important;
  }

  path:first-child {
    fill: $color-main !important;
  }
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  .card {
    box-shadow: 0 7px 15px -12px rgb(0 0 0/80%);
  }
}

.clear {
  clear: both;
}

.one-blog-content {
  a.btn {
    width: fit-content;
    margin: 0 auto;
  }

  img {
    width: 100%;
  }
}
