@import 'src/assets/styles/styles-variables';


.card {
  margin: 0 10px;
  width: 100%;
  border-radius: 15px;
  background-color: $color-beige-2x-light;

  &.white {
    background-color: $color-white;
  }
}
